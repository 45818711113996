<template>
  <div id="record">
    <el-dialog
      :title="recordFormTitle"
      width="680px"
      :visible.sync="recordDialogVisible"
      :close-on-click-modal="false"
      @close="recordDialogClose"
    >
      <el-form
        ref="recordFormRef"
        :model="recordForm"
        :rules="recordFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item label="订单号" prop="orderNo">
              <el-input v-model="recordForm.orderNo" placeholder="请输入订单号">
                <i slot="suffix" class="el-input__icon el-icon-success" @click="selectNotice" />
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="订单号" prop="orderNo">
              <el-select
                v-model="recordForm.orderNo"
                placeholder="请选择订单号"
                clearable
                filterable
                @change="selectNotice"
              >
                <el-option
                  v-for="item in noticeList"
                  :key="item.id"
                  :label="item.noticeNo"
                  :value="item.noticeNo"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="recordForm.customerId"
                placeholder="请选择客户"
                clearable
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item> -->
            <el-form-item label="客户名称" prop="customerName">
              <el-input
                v-model="recordForm.customerName"
                placeholder="请输入客户名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="receivingAddress">
              <el-input
                v-model="recordForm.receivingAddress"
                placeholder="请输入详细地址"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productId">
              <el-select
                v-model="recordForm.productId"
                placeholder="请选择产品"
                clearable
                @change="productChange"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                />
              </el-select>
              <!-- <el-input v-model="recordForm.productName" placeholder="请输入产品名称" clearable /> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="recordForm.spec"
                placeholder="请输入型号规格"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="recordForm.batchNo"
                placeholder="请输入批号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input
                v-model="recordForm.validPeriod"
                placeholder="请输入有效期"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价" prop="unitPrice">
              <el-input
                v-model="recordForm.unitPrice"
                placeholder="请输入单价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="recordForm.quantity"
                placeholder="请输入数量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-input
                v-model="recordForm.amount"
                placeholder="请输入金额"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订货日期" prop="orderDate">
              <el-date-picker
                v-model="recordForm.orderDate"
                placeholder="请选择订货日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货日期" prop="deliveryDate">
              <el-date-picker
                v-model="recordForm.deliveryDate"
                placeholder="请选择发货日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="付款方式" prop="paymentMethod">
              <el-radio-group v-model="recordForm.paymentMethod">
                <el-radio label="1"> 货到付款 </el-radio>
                <el-radio label="2"> 临时欠款 </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物流单号" prop="logisticsNo">
              <el-input
                v-model="recordForm.logisticsNo"
                placeholder="请输入物流单号"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="recordDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="recordFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="订单号">
        <el-input
          v-model="searchForm.orderNo"
          placeholder="请输入订单号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="recordPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="orderNo" label="订单号" />
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="receivingAddress" label="详细地址" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="unitPrice" label="单价" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="amount" label="金额" />
      <el-table-column label="订货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.orderDate">{{
            scope.row.orderDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDate">{{
            scope.row.deliveryDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="付款方式">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentMethod === '1'">货到付款</span>
          <span v-if="scope.row.paymentMethod === '2'">临时欠款</span>
        </template>
      </el-table-column>
      <el-table-column prop="logisticsNo" label="物流单号" />
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="recordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addRecord,
  deleteRecord,
  updateRecord,
  selectRecordInfo,
  selectRecordList,
} from "@/api/sale/record";
// import { selectCustomerList } from '@/api/sale/customer'
import { selectNoticeList, selectNoticeByNo } from "@/api/sale/notice";

export default {
  data() {
    return {
      recordDialogVisible: false,
      recordFormTitle: "",
      recordForm: {
        id: "",
        orderNo: "",
        customerId: "",
        customerName: "",
        receivingAddress: "",
        productName: "",
        spec: "",
        batchNo: "",
        validPeriod: "",
        unitPrice: "",
        quantity: "",
        amount: "",
        orderDate: "",
        deliveryDate: "",
        paymentMethod: "",
        logisticsNo: "",
      },
      recordFormRules: {
        orderNo: [
          {
            required: true,
            message: "订单号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // customerId: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      recordPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        orderNo: "",
      },
      productList: [],
      // customerList: [],
      noticeList: [],
    };
  },
  created() {
    selectRecordList(this.searchForm).then((res) => {
      this.recordPage = res;
    });
    // selectCustomerList().then(res => {
    //   this.customerList = res.list
    // })
    selectNoticeList().then((res) => {
      this.noticeList = res.list;
    });
  },
  methods: {
    recordDialogClose() {
      this.$refs.recordFormRef.resetFields();
      this.productList = [];
    },
    recordFormSubmit() {
      if (this.recordFormTitle === "销售记录详情") {
        this.recordDialogVisible = false;
        return;
      }
      this.$refs.recordFormRef.validate(async (valid) => {
        if (valid) {
          if (this.recordFormTitle === "新增销售记录") {
            this.recordForm.id = "";
            await addRecord(this.recordForm);
          } else if (this.recordFormTitle === "修改销售记录") {
            await updateRecord(this.recordForm);
          }
          this.recordPage = await selectRecordList(this.searchForm);
          this.recordDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.recordFormTitle = "新增销售记录";
      this.recordDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteRecord(row.id);
        if (this.recordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.recordPage = await selectRecordList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.recordFormTitle = "修改销售记录";
      this.recordDialogVisible = true;
      this.selectRecordInfoById(row.id);
    },
    handleInfo(index, row) {
      this.recordFormTitle = "销售记录详情";
      this.recordDialogVisible = true;
      this.selectRecordInfoById(row.id);
    },
    selectRecordInfoById(id) {
      selectRecordInfo(id).then((res) => {
        this.recordForm.id = res.id;
        this.recordForm.orderNo = res.orderNo;
        this.recordForm.customerId = res.customerId;
        this.recordForm.customerName = res.customerName;
        this.recordForm.receivingAddress = res.receivingAddress;
        this.recordForm.productName = res.productName;
        this.recordForm.spec = res.spec;
        this.recordForm.batchNo = res.batchNo;
        this.recordForm.validPeriod = res.validPeriod;
        this.recordForm.unitPrice = res.unitPrice;
        this.recordForm.quantity = res.quantity;
        this.recordForm.amount = res.amount;
        this.recordForm.orderDate = res.orderDate;
        this.recordForm.deliveryDate = res.deliveryDate;
        this.recordForm.paymentMethod = res.paymentMethod;
        this.recordForm.logisticsNo = res.logisticsNo;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectRecordList(this.searchForm).then((res) => {
        this.recordPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectRecordList(this.searchForm).then((res) => {
        this.recordPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectRecordList(this.searchForm).then((res) => {
        this.recordPage = res;
      });
    },
    // customerChange (value) {
    //   if (value) {
    //     let customer = this.customerList.find(item => item.id === value)
    //     this.recordForm.customerName = customer.customerName
    //     this.recordForm.receivingAddress = customer.receivingAddress
    //   } else {
    //     this.recordForm.customerName = ''
    //     this.recordForm.receivingAddress = ''
    //   }
    // },
    selectNotice(value) {
      this.recordForm.customerId = "";
      this.recordForm.customerName = "";
      this.recordForm.receivingAddress = "";
      this.recordForm.orderDate = "";
      this.productList = [];
      this.recordForm.productId = "";
      this.recordForm.productName = "";
      this.recordForm.spec = "";
      this.recordForm.quantity = "";
      if (this.recordForm.orderNo) {
        selectNoticeByNo(this.recordForm.orderNo).then((res) => {
          this.recordForm.customerId = res.customerId;
          this.recordForm.customerName = res.customerName;
          this.recordForm.receivingAddress = res.detailedAddress;
          this.recordForm.orderDate = res.orderDate;
          this.productList = res.detailList;
        });
      }
    },
    productChange(value) {
      if (value) {
        let product = this.productList.find((item) => item.id === value);
        this.recordForm.productName = product.productName;
        this.recordForm.spec = product.spec;
        this.recordForm.quantity = product.quantityBatch;
      } else {
        this.recordForm.productName = "";
        this.recordForm.spec = "";
        this.recordForm.quantity = "";
      }
    },
  },
};
</script>

<style>
</style>
